import React from "react";
import { connect } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";
import { isMobileSelector } from "app/datamodel/Device/selectors";
import InboxV2 from "./inbox-v2";
import InboxV2Mobile from "./inbox-v2-mobile.jsx";

const mapStateToProps = (state) => ({
  token: getToken(state),
  isMobile: isMobileSelector(state),
});

const InboxV2MobileOrDesktop = (props) => {
  if (props.isMobile) {
    return <InboxV2Mobile {...props} />;
  }
  return <InboxV2 {...props} />;
};

export default connect(mapStateToProps)(InboxV2MobileOrDesktop);
