import React, { useState, useCallback } from "react";
import Footer from "components/Layout/Footer";
import LayoutWrapper from "components/Layout/Wrapper";
import { Header } from "./components/";
import { getPathToRequests, getPathToChats, SUBPATHS } from "./routes";
import styled from "styled-components";
import RequestsSwiper from "./requests-swiper";
import ChatBrowser from "./chat-browser";
import { mapToModel } from "./utils";
import { parseConnections } from "datamodel/Inbox/utils";
import { fetchDataForInbox } from "./hooks";
import { BADI_COLORS, COLORS } from "app/badi-components";
import { Link } from "react-router";
import { inboxV2Model as model } from "./models";
import LoadingSpinner from "app/components/Icons/LoadingSpinner";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 200px;
  margin-top: 20px;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 4px solid ${COLORS.NEUTRAL_10};
`;

Nav.Link = styled(Link)`
  ${({ emphasised }) => `font-weight: ${emphasised ? "bold" : "normal"};`}
  padding: 1px;
  padding-bottom: 10px;
  :hover {
    text-decoration: none;
    box-shadow: 0 0 0 4px ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
    clip-path: inset(0px 1px -5px 1px);
  }
`;

Nav.Icon = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-left: 10px;
`;

const LoadingBackgroundContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
`;

const LoadingBackground = () => (
  <LoadingBackgroundContainer>
    <LoadingSpinner color={BADI_COLORS.NEUTRAL.NEUTRAL_80} />
  </LoadingBackgroundContainer>
);

const isPath = (matchee, matchingPath) =>
  typeof matchingPath != "undefined" && matchee == matchingPath;

const InboxV2 = ({ params: { path }, location, router, token }) => {
  const [currentRequestIndex, setCurrentRequestIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { requests, chats } = fetchDataForInbox(token);
  const requestsModel = requests.isLoaded
    ? requests.data.map((request) => mapToModel(model, request))
    : [];
  const chatsModel = chats.isLoaded ? parseConnections(chats.data) : [];

  const showLoadingScreenFor = (ms) => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), ms);
  };

  const nextRequest = () => {
    const newIndex = currentRequestIndex + 1;
    if (newIndex > requestsModel.length - 1) {
      return;
    }
    showLoadingScreenFor(500);
    setCurrentRequestIndex(newIndex);
  };

  const previousRequest = () => {
    const newIndex = currentRequestIndex - 1;
    if (newIndex < 0) {
      return;
    }
    showLoadingScreenFor(500);
    setCurrentRequestIndex(newIndex);
  };

  const currentRequest = requestsModel[currentRequestIndex];

  const requestsCount = requestsModel.length;
  const chatsCount = chatsModel.length;

  const renderNavBar = useCallback(() => {
    const requestsText = Number.isInteger(requestsCount)
      ? translate("inbox-v2.nav-bar.requests.title-with-count", {
          count: requestsCount,
        })
      : translate("inbox-v2.nav-bar.requests.title");
    const chatsText = Number.isInteger(chatsCount)
      ? translate("inbox-v2.nav-bar.chats.title-with-count", {
          count: chatsCount,
        })
      : translate("inbox-v2.nav-bar.chats.title");

    return (
      <Nav>
        <Nav.Link
          emphasised={isPath(SUBPATHS.REQUESTS, path)}
          to={getPathToRequests()}
          decoration="none"
        >
          {requestsText}
          <Nav.Icon src={getPublicAsset("icons/requests.svg")} />
        </Nav.Link>
        <Nav.Link
          emphasised={isPath(SUBPATHS.CHATS, path)}
          to={getPathToChats()}
        >
          {chatsText}
          <Nav.Icon src={getPublicAsset("icons/chats.svg")} />
        </Nav.Link>
      </Nav>
    );
  }, [path, requestsCount, chatsCount]);

  return (
    <LayoutWrapper footer={<Footer campaign="inbox_footer" />}>
      <Container>
        <Header>Inbox</Header>
        {isPath(SUBPATHS.REQUESTS, path) && Boolean(currentRequest) && (
          <RequestsSwiper
            nextRequest={nextRequest}
            previousRequest={previousRequest}
            currentConnection={currentRequest}
            renderNavBar={renderNavBar}
          />
        )}
        {isPath(SUBPATHS.CHATS, path) && chatsModel.length && (
          <ChatBrowser
            connections={chatsModel}
            location={location}
            router={router}
          />
        )}
      </Container>
      {isLoading && <LoadingBackground />}
    </LayoutWrapper>
  );
};

export default InboxV2;
