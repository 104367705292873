import React, { useState } from "react";
import Layout from "components/Layout/Wrapper";
import ProgressiveImg from "app/components/ProgressiveImg";
import styled from "styled-components";
import { Heading } from "@badi/badi-components";
import { getGoldUpgradePath } from "app/Sections/plans/routes";
import Image from "components/Image/Image";
import { Tag } from "./components";
import {
  Button,
  LinkButton,
  BUTTON_TYPES,
  BADI_COLORS,
  Text,
} from "app/badi-components";
import { fetchDataForInbox } from "./hooks";
import { mapToModel } from "./utils";
import {
  acceptEnquiry,
  leaveInboxConversation,
} from "app/datamodel/Conversation/api";
import { inboxV2MobileModel as model } from "./models";

const TagCategoryHeading = styled.h5`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 30px 30px 0 0;
  background-color: white;
  transform: translateY(-40px);
  padding: 20px;
`;

ProfileDetails.TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RoomDetailsCard = styled.div`
  display: flex;
  flex-direction: horizontal;
  margin-top: 10px;
`;

const CtaContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px;
`;

const FloatingSection = styled.div`
  border-top: 1px solid black;
  padding-top: 10px;
  border-radius: 20px;
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
`;

const Spacer = styled.div`
  height: 60px;
`;

const NextPreviousButton = styled.button`
  background-color: blue;
  width: 100px;
  height: 50px;
  position: fixed;
  top: 60px;
  z-index: 99;
  right: ${({ side }) => (side == "right" ? 0 : "auto")};
`;

const PersonalityAndInterestsSection = ({ tags }) => (
  <>
    <Heading level={4}>Personality and Interests</Heading>
    {Object.values(tags).map(({ key, title, iconSrc, iconAlt, entries }) => (
      <div key={key}>
        <TagCategoryHeading>
          <Image src={iconSrc} alt={iconAlt} />
          <b>{title}</b>
        </TagCategoryHeading>
        {entries.map(({ id, label }) => (
          <Tag key={id}>{label}</Tag>
        ))}
      </div>
    ))}
  </>
);

const InboxV2Mobile = ({ token }) => {
  const [currentRequestIndex, setCurrentRequestIndex] = useState(0);
  const { requests } = fetchDataForInbox(token);
  const requestsModel = requests.isLoaded
    ? requests.data.map((request) => mapToModel(model, request))
    : [];

  if (requestsModel.length == 0) {
    return <p>Loading</p>;
  }

  const goToPreviousRequest = () => {
    setCurrentRequestIndex((currentRequestIndex - 1) % requestsModel.length);
  };
  const goToNextRequest = () => {
    setCurrentRequestIndex((currentRequestIndex + 1) % requestsModel.length);
  };

  const {
    id,
    user,
    room,
    matchesStatusLabel,
    hasBadiGold,
    payToMatchRequired,
  } = requestsModel[currentRequestIndex];

  const onClickAcceptConnection = () => {
    acceptEnquiry({ token, connectionId: id });
  };
  const onClickRejectConnection = () => {
    leaveInboxConversation(id, token);
  };

  return (
    <Layout>
      <NextPreviousButton
        disabled={currentRequestIndex == 0}
        side="left"
        onClick={goToPreviousRequest}
      >
        Previous
      </NextPreviousButton>
      <NextPreviousButton
        disabled={currentRequestIndex == requestsModel.length - 1}
        side="right"
        onClick={goToNextRequest}
      >
        Next
      </NextPreviousButton>

      <ProgressiveImg
        key={id}
        src={user.picture}
        tinySrc={user.picture_small}
        aspectRatio={100}
      />
      <ProfileDetails>
        <ProfileDetails.TopSection>
          <Heading level={1}>{`${user.name}, ${user.age}`}</Heading>
          <div>
            {user.genderIcon}
            {user.occupationIcon}
          </div>
        </ProfileDetails.TopSection>
        <p>{user.occupationDescription}</p>
        <p>{user.bio}</p>
        {Object.keys(user.tags).length > 0 && (
          <PersonalityAndInterestsSection tags={user.tags} />
        )}
        <div>
          <Heading level={4}>Languages</Heading>
          {user.languages}
        </div>
        {user.studiesDescription && (
          <div>
            <Heading level={4}>Studies</Heading>
            {user.studiesDescription}
          </div>
        )}
        <RoomDetailsCard>
          <div>
            <b>{room.title}</b>
            <p>{room.address}</p>
          </div>
          <Image
            styles={{ height: "100px", borderRadius: "5px" }}
            src={room.picture}
          />
        </RoomDetailsCard>
      </ProfileDetails>
      <FloatingSection>
        <Text
          body={"12-Medium"}
          color={hasBadiGold ? BADI_COLORS.ADDITIONAL.ADDITIONAL_06 : null}
          margin={"0 20px"}
        >
          {matchesStatusLabel}
        </Text>
        {payToMatchRequired ? (
          <LinkButton
            variant={BUTTON_TYPES.GOLD_GRADIENT}
            href={getGoldUpgradePath({ roomId: room.id })}
            fullWidth={true}
            style={{ margin: "10px" }}
          >
            Get Badi Gold
          </LinkButton>
        ) : (
          <CtaContainer>
            <Button
              variant={BUTTON_TYPES.SECONDARY}
              onClick={onClickRejectConnection}
              fullWidth={true}
            >
              Reject
            </Button>
            <Button
              variant={hasBadiGold ? BUTTON_TYPES.GOLD_GRADIENT : null}
              onClick={onClickAcceptConnection}
              fullWidth={true}
            >
              Accept
            </Button>
          </CtaContainer>
        )}
      </FloatingSection>
      <Spacer />
    </Layout>
  );
};

export default InboxV2Mobile;
