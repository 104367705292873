import { useState, useEffect } from "react";
import { fetchInbox } from "datamodel/Inbox/api";
import { getAPIUrl } from "base/shared/Utils";
import envVars from "base/shared/Env";
import { CONNECTION_STATUS_CODE } from "datamodel/Conversation/constants";
import fetch from "base/shared/fetch-data";

const getConnectionEnquiry = async (id, token) => {
  const { BADI_CONNECTION_ENQUIRY } = envVars();
  const serverUrl = getAPIUrl(BADI_CONNECTION_ENQUIRY.replace(":id", id));

  const response = await fetch(serverUrl, {
    token,
  });
  return response.data;
};

const fetchData = async ({ token }) => {
  const result = await fetchInbox(
    { token },
    {
      page: 1,
      per: 30,
      current_user_role: "lister",
    },
  );
  const [enquiryPendingConnections, otherConnections] =
    result.data.connections.reduce(
      ([enquiryPendingConnections, otherConnections], connection) => {
        if (connection.status === CONNECTION_STATUS_CODE.ENQUIRY_PENDING) {
          return [[...enquiryPendingConnections, connection], otherConnections];
        } else {
          return [enquiryPendingConnections, [...otherConnections, connection]];
        }
      },
      [[], []],
    );
  const requests = await Promise.all(
    enquiryPendingConnections.map(async (connection) => {
      const enquiry = await getConnectionEnquiry(connection.id, token);
      return { connection, enquiry };
    }),
  );
  return {
    requests: {
      isLoaded: true,
      data: requests,
    },
    chats: {
      isLoaded: true,
      data: otherConnections,
    },
  };
};

const fetchDataForInbox = (token) => {
  const [requests, setRequests] = useState({ isLoaded: false, data: [] });
  const [chats, setChats] = useState({ isLoaded: false, data: [] });
  useEffect(() => {
    fetchData({ token }).then(({ requests, chats }) => {
      setRequests(requests);
      setChats(chats);
    });
  }, []);

  return {
    requests,
    chats,
  };
};

export { fetchDataForInbox };
